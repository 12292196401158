import axios from "axios";
import { axiosInstance } from "../DataLayer/axiosInstance";
import { jwtDecode } from "jwt-decode";
import { baseUrl } from "../DataLayer/Constants";

export const AddOrUpdateGenie = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axiosInstance.post(
      "/api/Genie/AddOrUpdateGenie",
      data,
      { headers }
    );
    return response;
  } catch (error) {
    return "Something went wrong. Please try again...!";
  }
};

export const GetAllGenie = async () => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axiosInstance.get("/api/Genie/GetAllGenie", {
      headers,
    });
    if (response?.status === 200) {
      return response?.data;
    }
    return [];
  } catch (error) {
    return "Something went wrong. Please try again...!";
  }
};

export const ActivateOrDeactivateGenies = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axiosInstance.post(
      "/api/Genie/ActivateOrDeactivateGenies",
      data,
      { headers }
    );
    if (response?.status === 200) {
      return response?.data;
    }
    return [];
  } catch (error) {
    return "Something went wrong. Please try again...!";
  }
};

export const LinkOrUnLinkGroupGenies = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axiosInstance.post(
      "/api/Genie/LinkOrUnLinkGroupGenies",
      data,
      { headers }
    );
    return response;
  } catch (error) {
    return "Something went wrong. Please try again...!";
  }
};

export const GetGroupMappedToGenie = async (genieId) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axiosInstance.get(
      `/api/Genie/GetGroupMappedToGenie?id=${genieId}`,
      { headers }
    );
    if (response?.status === 200) {
      return response?.data;
    }
    return [];
  } catch (error) {
    return "Something went wrong. Please try again...!";
  }
};

export const GetGenieByUserGroup = async () => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axiosInstance.get(
      `/api/Genie/GetGenieByUserGroup`,
      { headers }
    );
    if (response?.status === 200) {
      return response?.data;
    }
    return [];
  } catch (error) {
    return "Something went wrong. Please try again...!";
  }
};

export const UpdateGenieOrder = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axiosInstance.post(
      "/api/Genie/UpdateGenieOrder",
      data,
      { headers }
    );
    if (response?.status === 200) {
      return response?.data;
    }
    return [];
  } catch (error) {
    return "Something went wrong. Please try again...!";
  }
};