import React, { useEffect, useState } from "react";
import { authContext } from "../CommonComponets/adalConfig";
import { Collapse, Card, Select, Spin, Button } from "antd";
import { baseUrl } from "../DataLayer/Constants";
import { axiosInstance } from "../DataLayer/axiosInstance";

const GroupUsers = () => {
  // const { groupServerCount, userServerCount, userCount, groupCount } = data;
  const [items, setItems] = useState([]);
  const [groupSyncData, setGroupSyncData] = useState({
    applicationUsers: [],
    usersToAdd: [],
    usersToRemove: [],
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    collapseItems();
  }, []);

  const collapseItems = async () => {
    const groupData = await getGroupData();
    setAvailableGroups(
      groupData?.map((group) => ({
        ...group,
        value: group?.id,
        label: group?.groupName,
      }))
    );
  };

  const getGroupData = async () => {
    try {
      const response = await axiosInstance.get(
        baseUrl + "/api/Group/GetAllGroups",
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return response.status === 200 ? response.data : [];
    } catch {
      return [];
    }
  };

  const getGroupMemberData = async (groupId) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        baseUrl + `/api/User/GetGroupAdUsers?groupId=${groupId}`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.status === 200) {
        setGroupSyncData(response?.data);
      } else {
        setGroupSyncData({});
      }
      setLoading(false);
    } catch {
      setLoading(false);
      setGroupSyncData({});
    }
  };

  const syncData = async () => {
    setLoading(true);
    try {
      const headers = {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = {
        toAdd: [
          {
            groupId: selectedGroup,
            userIds: groupSyncData?.usersToAdd
              ?.map((e) => e?.userId)
              ?.filter((f) => ![0, null, undefined]?.includes(f)),
          },
        ],
        toRemove: [
          {
            groupId: selectedGroup,
            userIds: groupSyncData?.usersToRemove
              ?.map((e) => e?.userId)
              ?.filter((f) => ![0, null, undefined]?.includes(f)),
          },
        ],
      };
      await axiosInstance.post(baseUrl + "/api/Group/SyncGroupUsers", data, {
        headers,
      });
      getGroupMemberData(selectedGroup);
      setLoading(true);
    } catch (error) {
      getGroupMemberData(selectedGroup);
      setLoading(false);
      //error handle
    }
  };

  return (
    <div>
      <Spin spinning={loading}>
        <p>
          <b>Group Members</b>
        </p>
        <Collapse
          items={[
            {
              key: 2,
              label: "Goup Member Sync Section",
              children: (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      gap: "20px",
                    }}
                  >
                    <Select
                      key={"GroupList"}
                      showSearch
                      style={{
                        width: 400,
                      }}
                      placeholder="Search to Select"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      value={selectedGroup}
                      options={availableGroups}
                      onChange={(value, option) => {
                        setSelectedGroup(value);
                        getGroupMemberData(value);
                      }}
                    />
                    <Button
                      onClick={() => {
                        getGroupMemberData(selectedGroup);
                      }}
                    >
                      Refresh
                    </Button>
                    <Button
                      disabled={
                        !(
                          groupSyncData?.usersToAdd?.length > 0 ||
                          groupSyncData?.usersToRemove?.length > 0
                        )
                      }
                      onClick={() => {
                        syncData();
                      }}
                    >
                      Sync Data
                    </Button>
                  </div>
                  <p style={{ fontSize: "12px", color: "red" }}>
                    {groupSyncData?.message}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      gap: "20px",
                    }}
                  >
                    <Card
                      title="Available users"
                      bordered={true}
                      hoverable={true}
                      style={{
                        height: "350px",
                      }}
                      styles={{
                        body: {
                          height: "280px",
                          overflowY: "auto",
                          scrollbarWidth: "thin",
                          width: "300px",
                          wordBreak: "break-all",
                        },
                      }}
                    >
                      {groupSyncData?.applicationUsers?.length > 0 ? (
                        groupSyncData?.applicationUsers?.map((e, index) => {
                          return (
                            <p className="fs-10">
                              {index + 1 + ". " + e?.userEmail}
                            </p>
                          );
                        })
                      ) : (
                        <p>No users Found</p>
                      )}
                    </Card>
                    <Card
                      title="Users need to be synced"
                      bordered={true}
                      hoverable={true}
                      style={{
                        height: "350px",
                      }}
                      styles={{
                        body: {
                          height: "280px",
                          overflowY: "auto",
                          scrollbarWidth: "thin",
                          width: "300px",
                          wordBreak: "break-all",
                        },
                      }}
                    >
                      {groupSyncData?.usersToAdd?.length > 0 ? (
                        groupSyncData?.usersToAdd?.map((e, index) => {
                          return (
                            <p className="fs-10">
                              {index + 1 + ". " + e?.userEmail}
                            </p>
                          );
                        })
                      ) : (
                        <p>No users Found</p>
                      )}
                    </Card>
                    <Card
                      title="Users need to be removed"
                      bordered={true}
                      hoverable={true}
                      style={{
                        height: "350px",
                      }}
                      styles={{
                        body: {
                          height: "280px",
                          overflowY: "auto",
                          scrollbarWidth: "thin",
                          width: "300px",
                          wordBreak: "break-all",
                        },
                      }}
                    >
                      {groupSyncData?.usersToRemove?.length > 0 ? (
                        groupSyncData?.usersToRemove?.map((e, index) => {
                          return (
                            <p className="fs-10">
                              {index + 1 + ". " + e?.userEmail}
                            </p>
                          );
                        })
                      ) : (
                        <p>No users Found</p>
                      )}
                    </Card>
                  </div>
                </div>
              ),
            },
          ]}
          defaultActiveKey={["1"]}
        />
      </Spin>
    </div>
  );
};

export default GroupUsers;
