import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Table,
  Typography,
  Row,
  Col,
  Pagination,
  notification,
  Dropdown,
  Menu,
  Spin,
  DatePicker,
} from "antd";
import CreateGroupDrawer from "./CreateGroupDrawer";
import { EditOutlined, ControlOutlined } from "@ant-design/icons";
import { axiosInstance } from "../DataLayer/axiosInstance";

const Logs = () => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [userName, setUserName] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [logsData, setLogsData] = useState([]);

  const { Search } = Input;

  const userColumn = [
    {
      title: "S.No",
      dataIndex: "index",
      render: (value, item, index) =>
        (pagination?.current - 1) * pagination?.pageSize + index + 1,
      width: 70,
    },
    {
      title: "User Email",
      dataIndex: "userEmail",
      render: (text, record) => <span className="fs-10">{text}</span>,
    },
    {
      title: "ProcessName",
      dataIndex: "processName",
      render: (text, record) => <span className="fs-10">{text}</span>,
    },
    {
      title: "Message",
      dataIndex: "message",
      render: (text, record) => <span className="fs-10">{text}</span>,
    },
    {
      title: "CreatedOn",
      dataIndex: "createdOn",
      render: (text, record) => (
        <span className="fs-10">
          {text ? new Date(text)?.toLocaleString() : text}
        </span>
      ),
    },
  ];

  // useEffect(() => {
  //   renderData();
  // }, []);

  const renderData = async () => {
    try {
      if (!fromDate && !toDate && !userName) {
        setLogsData([]);
        setFilteredLogs([]);
        return;
      }
      setLoading(true);
      const response = await axiosInstance.get(
        `/api/ActivityLogs/GetAllLogsByFiltered?fromDate=${
          !fromDate ? null : new Date(fromDate).toLocaleString()
        }&toDate=${!toDate ? null : new Date(toDate).toLocaleString()}&user=${
          userName || ""
        }`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setLoading(false);
      if (response.status === 200) {
        setLogsData(response.data);
        setFilteredLogs(response.data);
      } else {
        setLogsData([]);
        setFilteredLogs([]);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const disabledStartDate = (current) => {
    // Disable dates after today
    return current > new Date();
  };

  const disabledToDate = (current) => {
    // Disable dates after today
    if (fromDate) {
      return current < new Date(fromDate) || current > new Date();
    }
    return current > new Date();
  };

  const handleFromDateChange = (date, dateString) => {
    setFromDate(date ? new Date(date) : null);
    // Clear the end date if the selected start date is greater than the current end date
    if (toDate && date && date.isAfter(toDate)) {
      setToDate(null);
    }
  };

  const handleToDateChange = (date, dateString) => {
    setToDate(date ? new Date(date) : null);
  };

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      current: pagination.current,
    });
  };

  const filterLogs = (searchValue) => {
    if (!searchValue) {
      setFilteredLogs(logsData);
      return;
    }
    const lowerSearchValue = searchValue?.toLowerCase();

    let filtered = logsData?.filter(
      (data) =>
        data?.processName?.toLowerCase()?.includes(lowerSearchValue) ||
        data?.userEmail?.toLowerCase()?.includes(lowerSearchValue) ||
        data?.message?.toLowerCase()?.includes(lowerSearchValue)
    );
    setFilteredLogs(filtered);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    filterLogs(value);
  };

  return (
    <div className="p-5">
      <Spin spinning={loading}>
        <p>
          <b>Activity Logs</b>
        </p>
        <div className="flex-wrap">
          <Search
            placeholder="Search by user..."
            onChange={(e) => {
              setUserName(e?.target?.value || "");
            }}
            style={{ width: "250px", height: "25px" }} // Adjust height if needed
            value={userName}
          />
          <div>
            <span>From Date:</span>&nbsp;&nbsp;
            <DatePicker
              size={"small"}
              placeholder="From date"
              disabledDate={disabledStartDate}
              onChange={handleFromDateChange}
            />
          </div>
          <div>
            <span>To Date:</span>&nbsp;&nbsp;
            <DatePicker
              size={"small"}
              placeholder="To date"
              disabledDate={disabledToDate}
              onChange={handleToDateChange}
            />
          </div>
          <Button
            size={"small"}
            ghost
            style={{
              fontSize: "12px",
              height: "25px",
              border: "1.5px solid green",
              color: "green",
              padding: "0 10px",
            }}
            onClick={() => {
              renderData();
            }}
          >
            Search
          </Button>
        </div>
        <hr />
        <div>
          <Search
            placeholder="Search..."
            onChange={(e) => handleSearch(e.target.value)}
            style={{ width: "250px", height: "25px" }} // Adjust height if needed
            value={searchText}
          />
        </div>
        <br />
        <div>
          <Table
            bordered
            size="small"
            dataSource={filteredLogs}
            columns={userColumn}
            rowKey="id"
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "20", "50"],
              onChange: (page, pageSize) => {
                setPagination({ current: page, pageSize });
              },
              onShowSizeChange: (current, size) => {
                setPagination({ current: 1, pageSize: size }); // Reset to first page on page size change
              },
            }}
            onChange={handleTableChange} // Handle pagination changes
            scroll={{ y: 260 }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default Logs;
