import React, { useEffect, useState } from "react";
import {
  LinkOrUnLinkGroupGenies,
  GetGroupMappedToGenie,
} from "../Services/GenieApiServices";
import { Drawer, Button, Select } from "antd";

const MapGroupsToGenies = ({ isOpen, genieId, groups, onClose }) => {
  const [exisingGenieGroupIds, setExisingGenieGroupIds] = useState([]);

  useEffect(() => {
    if (isOpen && genieId > 0) {
      getData();
    }
    return () => {
      setExisingGenieGroupIds([]);
    };
  }, [genieId]);

  const getData = async () => {
    try {
      const response = await GetGroupMappedToGenie(genieId);
      setExisingGenieGroupIds(response);
    } catch (error) {}
  };

  const handleSubmit = async () => {
    try {
      const data = {
        genieId: genieId,
        groupIds: exisingGenieGroupIds,
      };
      const response = await LinkOrUnLinkGroupGenies(data);
      if (response?.status === 200) {
        onClose();
      } else {
        // some thing went wrong....!
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Drawer
        title={"Map Group To Genie"}
        width={400}
        onClose={(e) => {
          onClose();
        }}
        open={isOpen}
        maskClosable={false}
        closable={false}
        footer={
          <div style={{ textAlign: "right" }}>
            <Button
              onClick={() => {
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleSubmit();
              }}
              type="primary"
            >
              Save
            </Button>
          </div>
        }
      >
        <Select
          mode="multiple"
          showSearch
          style={{ width: "100%" }}
          value={exisingGenieGroupIds}
          onChange={(val) => {
            setExisingGenieGroupIds(val);
          }}
          placeholder="Select Groups"
          filterOption={(input, option) =>
            option?.children?.toLowerCase().includes(input.toLowerCase())
          }
        >
          {groups?.map((group) => (
            <Select.Option key={group?.id} value={group?.id}>
              {group?.groupName}
            </Select.Option>
          ))}
        </Select>
      </Drawer>
    </div>
  );
};
export default MapGroupsToGenies;
