import React, { useState, useEffect } from 'react';
import { Drawer, Form, Input, Button, Upload, message, Modal, notification, Spin } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { axiosInstance } from '../DataLayer/axiosInstance';
import '../index.css';

// Helper function to convert file to base64
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const CreateApplicationDrawer = ({ visible, onClose, application, onApplicationUpdate }) => {
  const [applicationName, setApplicationName] = useState('');
  const [applicationUrl, setApplicationUrl] = useState('');
  const [applicationImageString, setApplicationImageString] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [iconError, setIconError] = useState('');
  const [urlError, setUrlError] = useState('');
  const [isUrlValid, setIsUrlValid] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!visible && !application) {
      setApplicationName('');
      setApplicationUrl('');
      setApplicationImageString('');
      setFileList([]);
    }
  }, [visible, application]);

  useEffect(() => {
    if (application) {
      setApplicationName(application?.applicationName);
      setApplicationUrl(application?.applicationUrl);
      setApplicationImageString(application?.applicationImageString);
      setFileList(
        application?.applicationImageString
          ? [{ uid: '-1', url: application?.applicationImageString, name: 'Icon', status: 'done' }]
          : []
      );
    } else {
      setApplicationName('');
      setApplicationUrl('');
      setApplicationImageString('');
      setFileList([]);
    }
  }, [application]);

  const handlePreview = async (file) => {
    if (!file?.url && !file?.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file?.url || file?.preview);
    setPreviewVisible(true);
    setPreviewTitle(file?.name || file?.url.substring(file?.url.lastIndexOf('/') + 1));
  };

  const handleIconChange = async ({ fileList }) => {
    if (fileList.length > 0) {
      const file = fileList[0]?.originFileObj || fileList[0];
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLimit = file.size / 1024 / 1024 < 10;

      if (!isJpgOrPng) {
        setIconError('*Invalid file type. Please upload a JPG or PNG file.');
        return;
      }
      if (!isLimit) {
        setIconError('*File size exceeds 10MB limit.');
        return;
      }
      const base64 = await getBase64(file);
      setApplicationImageString(base64); // Update icon state with base64
      setFileList([{ ...fileList[0], preview: base64, url: base64 }]);
      setIconError('');
    } else {
      setApplicationImageString(''); // Clear icon if no files
      setFileList([]);
      setIconError('*Mandatory Field');
    }
  };

  const handleSubmit = async () => {
    if (!applicationName) {
      message.error('Application Name is required!');
      return;
    }
    if (!isUrlValid) {
      message.error('Please enter a valid URL!');
      return;
    }
    if (!applicationImageString) {
      message.error('Image is required!');
      return;
    }

    setLoading(true);
    try {
      if (application) {
        // Update existing application
        const response = await axiosInstance.post('/api/Application/AddOrUpdateApplication', {
          id: application.id,
          applicationName,
          applicationUrl,
          applicationImageString,
          isActive,
        });
        notification.success({
          message: 'Update Successful',
          description: 'Application has been updated successfully.',
        });
      } else {
        // Create new application
        const response = await axiosInstance.post('/api/Application/AddOrUpdateApplication', {
          applicationName,
          applicationUrl,
          applicationImageString,
          isActive,
        });
        notification.success({
          message: 'Creation Successful',
          description: 'Application has been created successfully.',
        });
      }
      // Pass the updated data to parent
      onApplicationUpdate();
    } catch (error) {
      console.error('Error:', error);
      notification.error({
        message: 'Error',
        description: error?.response?.data?.message || 'An error occurred, please try again.',
      });
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const validateUrl = (url) => {
    try {
      new URL(url);
      setIsUrlValid(true);
      setUrlError('');
    } catch (e) {
      setIsUrlValid(false);
      setUrlError('Please enter a valid URL');
    }
  };

  const isFormValid = applicationImageString && applicationName && isUrlValid;

  return (
    <Spin spinning={loading}>
      <Drawer
        title={application ? 'Edit Application' : 'New Application'}
        width={400}
        onClose={onClose}
        open={visible}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => {
                if (application) {
                  setApplicationName(application?.applicationName);
                  setApplicationUrl(application?.applicationUrl);
                  setApplicationImageString(application?.applicationImageString);
                  setFileList(
                    application?.applicationImageString
                      ? [{ uid: '-1', url: application?.applicationImageString, name: 'Icon', status: 'done' }]
                      : []
                  );
                  setIconError('');
                }
                onClose();
              }} style={{ marginRight: 8 }}>
                Cancel
              </Button>
            <Button onClick={handleSubmit} type="primary" disabled={!isFormValid}>
              {application ? 'Update' : 'Create'}
            </Button>
          </div>
        }
        maskClosable={false}
        closable={false}
      >
        <Form layout="vertical">
          <Form.Item
            label="Application Name"
            validateStatus={!applicationName ? 'error' : ''}
            help={!applicationName ? 'Application Name is required.' : ''}
          >
            <Input value={applicationName} onChange={(e) => setApplicationName(e.target.value)} />
          </Form.Item>
          <Form.Item
            label="Icon"
            validateStatus={iconError ? 'error' : ''}
            help={iconError}
          >
            <Upload
              name="icon"
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview} // Preview in modal
              onChange={handleIconChange}
              customRequest={({ onSuccess, file }) => {
                // Simulate and avoid the Api call while uploading
                setTimeout(() => {
                  onSuccess("ok");
                  file.status = 'done';
                  handleIconChange({ fileList: [file] });
                }, 0);
              }}
              showUploadList={{ showRemoveIcon: true }}
              maxCount={1}
              className="upload-drag-area"
              accept="image/png, image/jpeg, image/gif, image/bmp, image/tiff, image/webp, image/svg+xml"
            >
              {fileList.length >= 1 ? null : (
                <div>
                  <p className="ant-upload-text">Drop here to attach or upload</p>
                  <p className="ant-upload-hint">Max size: 10MB</p>
                  <InboxOutlined className="ant-upload-drag-icon" />
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item
            label="URL"
            validateStatus={!isUrlValid ? 'error' : ''}
            help={urlError}
          >
            <Input value={applicationUrl} onChange={(e) => {
              setApplicationUrl(e.target.value);
              validateUrl(e.target.value);
            }} />
          </Form.Item>
        </Form>

        {/* Modal for previewing the image */}
        <Modal
          open={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={() => setPreviewVisible(false)}
        >
          <img alt="Icon Preview" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </Drawer>
    </Spin>
  );
};

export default CreateApplicationDrawer;
