import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Checkbox,
  Select,
  Button,
  Modal,
  Spin,
  Upload,
  Col,
} from "antd";
import { CloseOutlined, InboxOutlined } from "@ant-design/icons";
import { AddOrUpdateGenie } from "../Services/GenieApiServices";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const AddEditGenie = ({ selectedData, onSaveClick, isOpen, onCloseClick }) => {
  const [loading, setLoading] = useState(false);
  const [recordId, setRecordId] = useState(0);
  const [genieData, setGenieData] = useState(selectedData);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [iconError, setIconError] = useState("");
  const [applicationImageString, setApplicationImageString] = useState("");
  const [fileList, setFileList] = useState([]);

  const [tokenResponseMapping, setTokenResponseMapping] = useState([]);
  const [interactionPayloadMapping, setInteractionPayloadMapping] = useState(
    []
  );
  const [queryResponseMapping, setQueryResponseMapping] = useState([]);
  const [errorMessage, setErrorMessage] = useState({});
  const { TextArea } = Input;

  useEffect(() => {
    if (selectedData) {
      setApplicationImageString(selectedData?.image);
      setFileList(
        selectedData?.image
          ? [
              {
                uid: "-1",
                url: selectedData?.image,
                name: "Icon",
                status: "done",
              },
            ]
          : []
      );
    } else {
      setFileList("");
      setApplicationImageString("");
      setFileList([]);
    }
    if (selectedData?.id > 0) {
      const optionSet = getKeys(selectedData["tokenUrlResponseExample"]);
      const optionSet1 = getKeys(selectedData["interactionUrlPayLoad"]);
      const optionSet2 = getKeys(selectedData["interactionUrlResponseExample"]);
      setTokenResponseMapping(optionSet);
      setInteractionPayloadMapping(optionSet1);
      setQueryResponseMapping(optionSet2);
    }
    validateField();
    return () => {
      setGenieData({});
    };
  }, [selectedData]);

  const getKeys = (val) => {
    if (val) {
      try {
        const keys = Object.keys(JSON.parse(val));
        return keys?.map((e) => {
          return {
            value: e,
            label: e,
          };
        });
      } catch (error) {
        return [];
      }
    }
    return [];
  };

  const handlePreview = async (file) => {
    if (!file?.url && !file?.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file?.url || file?.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file?.name || file?.url.substring(file?.url.lastIndexOf("/") + 1)
    );
  };

  const handleIconChange = async ({ fileList }) => {
    if (fileList.length > 0) {
      const file = fileList[0]?.originFileObj || fileList[0];
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      const isLimit = file.size / 1024 / 1024 < 10;

      if (!isJpgOrPng) {
        setIconError("*Invalid file type. Please upload a JPG or PNG file.");
        return;
      }
      if (!isLimit) {
        setIconError("*File size exceeds 10MB limit.");
        return;
      }
      const base64 = await getBase64(file);
      setApplicationImageString(base64); // Update icon state with base64
      setFileList([{ ...fileList[0], preview: base64, url: base64 }]);
      setIconError("");
    } else {
      setApplicationImageString(""); // Clear icon if no files
      setFileList([]);
      setIconError("*Mandatory Field");
    }
  };

  const saveGenie = async () => {
    try {
      selectedData["image"] = applicationImageString;
      console.log(selectedData);
      setLoading(true);
      const response = await AddOrUpdateGenie(selectedData);
      setLoading(false);
      if (response?.status === 200) {
        onCloseClick(true);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const validateField = () => {
    const errMssg = {};
    // name validation
    if (!selectedData?.name) {
      errMssg["name"] = true;
    } else {
      errMssg["name"] = false;
    }
    //token part
    // token url
    if (!selectedData?.tokenUrl) {
      errMssg["tokenUrl"] = true;
    } else {
      errMssg["tokenUrl"] = false;
    }
    // token method
    if (!selectedData?.tokenUrlMethod) {
      errMssg["tokenUrlMethod"] = true;
    } else {
      errMssg["tokenUrlMethod"] = false;
    }
    //token payload
    if (!selectedData?.tokenUrlPayLoad) {
      errMssg["tokenUrlPayLoad"] = true;
    } else {
      errMssg["tokenUrlPayLoad"] = false;
    }
    //token response
    if (!selectedData?.tokenUrlResponseExample) {
      errMssg["tokenUrlResponseExample"] = true;
    } else {
      errMssg["tokenUrlResponseExample"] = false;
    }
    // interaction(query) part
    //query url
    if (!selectedData?.interactionUrl) {
      errMssg["interactionUrl"] = true;
    } else {
      errMssg["interactionUrl"] = false;
    }
    //query payload
    if (!selectedData?.interactionUrlPayLoad) {
      errMssg["interactionUrlPayLoad"] = true;
    } else {
      errMssg["interactionUrlPayLoad"] = false;
    }
    //query method
    if (!selectedData?.interactionUrlMethod) {
      errMssg["interactionUrlMethod"] = true;
    } else {
      errMssg["interactionUrlMethod"] = false;
    }
    //query response
    if (!selectedData?.interactionUrlResponseExample) {
      errMssg["interactionUrlResponseExample"] = true;
    } else {
      errMssg["interactionUrlResponseExample"] = false;
    }
    //query input key
    if (!selectedData?.interactionUrlQueryKey) {
      errMssg["interactionUrlQueryKey"] = true;
    } else {
      errMssg["interactionUrlQueryKey"] = false;
    }

    //query response key and user key unique check
    if (
      selectedData?.interactionUrlUserNameKey &&
      selectedData?.interactionUrlQueryKey &&
      selectedData?.interactionUrlUserNameKey ===
        selectedData?.interactionUrlQueryKey
    ) {
      errMssg["uqUniqueCheck"] = true;
    } else {
      errMssg["uqUniqueCheck"] = false;
    }

    setErrorMessage(errMssg);
  };

  const saveValidation = () => {
    const values = Object?.values(errorMessage)?.filter((e) => e);
    if(values?.length > 0){
      return true;
    }
    return false;
  }

  return (
    <Modal
      title={
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div>
              <span>
                {selectedData?.id > 0 ? "Update ChatBot" : "Create ChatBot"}{" "}
              </span>
              &nbsp;&nbsp;
              <Button
                type="primary"
                className="AddOrUpdateButton"
                size={"small"}
                style={{ width: "fit-content" }}
                onClick={() => {
                  saveGenie();
                }}
                disabled={saveValidation()}
              >
                Save
              </Button>
            </div>

            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              {/* <Button
            type="text"
            icon={
              <UndoOutlined style={{ transform: "rotate(120deg)" }} />
            }
            // onClick={handleRefresh}
            title="Refresh Chat"
          /> */}
              <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={() => {
                  onCloseClick();
                }}
                title="Close Chat"
              />
            </div>
          </div>
          <hr />
        </>
      }
      maskClosable={false}
      open={isOpen?.openDrawer}
      onCancel={() => {
        onCloseClick();
      }}
      footer={null}
      centered
      width="90%"
      closable={false}
      style={{ height: "90vh" }}
      // bodyStyle={{ padding: "0px", overflow: "hidden" }}
    >
      <div
        className="p-5"
        style={{ height: "75vh", overflow: "auto", scrollbarWidth: "thin" }}
      >
        <Spin spinning={loading}>
          {/* <div className="flex-wrap align-i-c">
            <p>
              <b>{!recordId && recordId <= 0 ? "Add Genie" : "Update Genie"}</b>
            </p>
            <Button
              type="primary"
              className="AddOrUpdateButton"
              size={"small"}
              style={{ width: "fit-content" }}
              onClick={() => {
                // setIsOpen({ openDrawer: !isOpen?.openDrawer, id: 0 });
                // setSelectedData(genieModal);
              }}
            >
              Save
            </Button>
          </div> */}
          {/* genie fields */}
          <div style={{ width: "100%" }}>
            {/* Name & description & isGlobal Section */}
            <div className="flex-wrap align-i-fs p-5">
              <div>
                <span className="f-key">Genie Name : </span>
                <Input
                  defaultValue={selectedData?.name || ""}
                  placeholder="Genie Name"
                  style={{
                    minWidth: "30%",
                    maxWidth: "max-content",
                    borderColor: errorMessage["name"] ? "red" : "#d9d9d9",
                  }}
                  onChange={(e) => {
                    selectedData["name"] = e?.target?.value || "";
                    setGenieData(selectedData);
                    validateField();
                  }}
                />
              </div>
              <div>
                <span className="f-key">Genie Description : </span>
                <Input
                  defaultValue={selectedData?.description || ""}
                  placeholder="Genie Description"
                  style={{ minWidth: "30%", maxWidth: "max-content" }}
                  onChange={(e) => {
                    selectedData["description"] = e?.target?.value || "";
                    setGenieData(selectedData);
                    validateField();
                  }}
                />
              </div>
              <div>
                <span className="f-key">Is Global : </span>
                <Checkbox
                  defaultChecked={selectedData?.isGlobal}
                  onChange={(e) => {
                    selectedData["isGlobal"] = e?.target?.checked;
                    setGenieData(selectedData);
                  }}
                />
              </div>
            </div>
            <div>
              <Upload
                name="icon"
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview} // Preview in modal
                onChange={handleIconChange}
                customRequest={({ onSuccess, file }) => {
                  // Simulate and avoid the Api call while uploading
                  setTimeout(() => {
                    onSuccess("ok");
                    file.status = "done";
                    handleIconChange({ fileList: [file] });
                  }, 0);
                }}
                showUploadList={{ showRemoveIcon: true }}
                maxCount={1}
                className="upload-drag-area"
                accept="image/png, image/jpeg, image/gif, image/bmp, image/tiff, image/webp, image/svg+xml"
              >
                {fileList.length >= 1 ? null : (
                  <div>
                    <p className="ant-upload-text">
                      Drop here to attach or upload
                    </p>
                    <p className="ant-upload-hint">Max size: 10MB</p>
                    <InboxOutlined className="ant-upload-drag-icon" />
                  </div>
                )}
              </Upload>
              {/* {errorMessage["image"] && (
                <p className="fs-10" style={{ color: "red" }}>
                  Image required
                </p>
              )} */}
            </div>
            {/* Token Configuration section start */}
            <p>
              <b>Token Configuration Section:</b>
            </p>
            <div className="flex-wrap align-i-fs p-5">
              <div className="w-30">
                <span className="f-key">Token Api Url : </span>
                <TextArea
                  defaultValue={selectedData?.tokenUrl || ""}
                  placeholder="Token Url"
                  style={{
                    borderColor: errorMessage["tokenUrl"] ? "red" : "#d9d9d9",
                  }}
                  autoSize={{
                    minRows: 2,
                    maxRows: 5,
                  }}
                  // style={{ minWidth: "40%", maxWidth: "max-content" }}
                  onChange={(e) => {
                    selectedData["tokenUrl"] = e?.target?.value;
                    setGenieData(selectedData);
                    validateField();
                  }}
                />
              </div>
              <div className="w-30">
                <span className="f-key">PayLoad : </span>
                <TextArea
                  defaultValue={selectedData?.tokenUrlPayLoad || ""}
                  placeholder="Token PayLoad"
                  style={{
                    borderColor: errorMessage["tokenUrlPayLoad"]
                      ? "red"
                      : "#d9d9d9",
                  }}
                  autoSize={{
                    minRows: 2,
                    maxRows: 5,
                  }}
                  // style={{ minWidth: "40%", maxWidth: "max-content" }}
                  onChange={(e) => {
                    selectedData["tokenUrlPayLoad"] = e?.target?.value;
                    setGenieData(selectedData);
                    validateField();
                  }}
                />
              </div>
              <div className="w-30">
                <span className="f-key">Token Api Method : </span>
                <Select
                  style={{
                    width: 120,
                  }}
                  status={errorMessage["tokenUrlMethod"] ? "error" : null}
                  defaultValue={selectedData?.tokenUrlMethod}
                  placeholder={"select key"}
                  onChange={(e) => {
                    selectedData["tokenUrlMethod"] = e;
                    setGenieData(selectedData);
                    validateField();
                  }}
                  options={[
                    {
                      value: "POST",
                      label: "POST",
                    },
                    {
                      value: "GET",
                      label: "GET",
                    },
                  ]}
                />
              </div>
            </div>
            <div className="flex-wrap align-i-fs p-5">
              <div className="w-30">
                <span className="f-key">Response : </span>
                <TextArea
                  defaultValue={selectedData?.tokenUrlResponseExample || ""}
                  placeholder="Token Api Response"
                  autoSize={{
                    minRows: 2,
                    maxRows: 5,
                  }}
                  style={{
                    borderColor: errorMessage["tokenUrlResponseExample"]
                      ? "red"
                      : "#d9d9d9",
                  }}
                  // style={{ minWidth: "40%", maxWidth: "max-content" }}
                  onChange={(e) => {
                    selectedData["tokenUrlResponseExample"] = e?.target?.value;
                    const optionSet = getKeys(e?.target?.value);
                    setTokenResponseMapping(optionSet);
                    setGenieData(selectedData);
                    validateField();
                  }}
                />
              </div>
              <div className="w-30">
                <span className="f-key">Response Mapping : </span>
                <Select
                  defaultValue={selectedData?.tokenUrlResponseMapping}
                  placeholder={"select key"}
                  style={{
                    width: 200,
                  }}
                  allowClear
                  onChange={(e) => {
                    selectedData["tokenUrlResponseMapping"] = e;
                    setGenieData(selectedData);
                  }}
                  options={
                    tokenResponseMapping ||
                    getKeys(selectedData["tokenUrlResponseExample"]) ||
                    []
                  }
                />
              </div>
            </div>
            {/* Token Configuration section end */}
            {/* Interaction/Query Configuration section start */}
            <p>
              <b>Query-(Response) Configuration:</b>
            </p>
            <div className="flex-wrap align-i-fs p-5">
              <div className="w-30">
                <span className="f-key">Query Api Url : </span>
                <TextArea
                  defaultValue={selectedData?.interactionUrl || ""}
                  style={{
                    borderColor: errorMessage["interactionUrl"]
                      ? "red"
                      : "#d9d9d9",
                  }}
                  placeholder="Query Url"
                  autoSize={{
                    minRows: 2,
                    maxRows: 5,
                  }}
                  onChange={(e) => {
                    selectedData["interactionUrl"] = e?.target?.value;
                    setGenieData(selectedData);
                    validateField();
                  }}
                />
              </div>
              <div className="w-30">
                <span className="f-key">PayLoad : </span>
                <TextArea
                  defaultValue={selectedData?.interactionUrlPayLoad || ""}
                  style={{
                    borderColor: errorMessage["interactionUrlPayLoad"]
                      ? "red"
                      : "#d9d9d9",
                  }}
                  placeholder="Query Api Payload"
                  autoSize={{
                    minRows: 2,
                    maxRows: 5,
                  }}
                  onChange={(e) => {
                    selectedData["interactionUrlPayLoad"] = e?.target?.value;
                    const optionSet = getKeys(e?.target?.value);
                    setGenieData(selectedData);
                    setInteractionPayloadMapping(optionSet);
                    validateField();
                  }}
                />
              </div>
              <div className="w-30">
                <span className="f-key">Query Api Method : </span>
                <Select
                  defaultValue={selectedData?.interactionUrlMethod}
                  placeholder={"select key"}
                  style={{
                    width: 120,
                  }}
                  status={errorMessage["interactionUrlMethod"] ? "error" : null}
                  onChange={(e) => {
                    selectedData["interactionUrlMethod"] = e;
                    setGenieData(selectedData);
                    validateField();
                  }}
                  options={[
                    {
                      value: "POST",
                      label: "POST",
                    },
                    {
                      value: "GET",
                      label: "GET",
                    },
                  ]}
                />
              </div>
            </div>
            <div className="flex-wrap align-i-fs p-5">
              <div className="w-30">
                <span className="f-key">UserName Input Key : </span>
                <Select
                  defaultValue={selectedData?.interactionUrlUserNameKey}
                  placeholder={"select key"}
                  style={{
                    width: 200,
                  }}
                  allowClear
                  status={errorMessage["uqUniqueCheck"] ? "error" : null}
                  onChange={(e) => {
                    selectedData["interactionUrlUserNameKey"] = e;
                    setGenieData(selectedData);
                    validateField();
                  }}
                  options={
                    interactionPayloadMapping ||
                    getKeys(selectedData["interactionUrlPayLoad"]) ||
                    []
                  }
                />
              </div>
              <div className="w-30">
                <span className="f-key">Query Input Key : </span>
                <br/>
                <Select
                  defaultValue={selectedData?.interactionUrlQueryKey}
                  placeholder={"select key"}
                  style={{
                    width: 200,
                  }}
                  status={(errorMessage["tokenUrlMethod"] || errorMessage["interactionUrlQueryKey"]) ? "error" : null}
                  onChange={(e) => {
                    selectedData["interactionUrlQueryKey"] = e;
                    setGenieData(selectedData);
                    validateField();
                  }}
                  options={
                    interactionPayloadMapping ||
                    getKeys(selectedData["interactionUrlPayLoad"]) ||
                    []
                  }
                />
              </div>
            </div>
            <div className="flex-wrap align-i-fs p-5">
              <div className="w-30">
                <span className="f-key">Response : </span>
                <TextArea
                  defaultValue={
                    selectedData?.interactionUrlResponseExample || ""
                  }
                  style={{
                    borderColor: errorMessage["interactionUrlResponseExample"]
                      ? "red"
                      : "#d9d9d9",
                  }}
                  placeholder="Query Api Response"
                  autoSize={{
                    minRows: 2,
                    maxRows: 5,
                  }}
                  onChange={(e) => {
                    selectedData["interactionUrlResponseExample"] =
                      e?.target?.value;
                    const optionSet = getKeys(e?.target?.value);
                    setQueryResponseMapping(optionSet);
                    setGenieData(selectedData);
                    validateField();
                  }}
                />
              </div>
              <div className="w-30">
                <span className="f-key">Response Mapping : </span>
                <Select
                  defaultValue={selectedData?.interactionUrlResponseMapping}
                  placeholder={"select key"}
                  style={{
                    width: 200,
                  }}
                  onChange={(e) => {
                    selectedData["interactionUrlResponseMapping"] = e;
                    setGenieData(selectedData);
                    validateField();
                  }}
                  options={
                    queryResponseMapping ||
                    getKeys(selectedData["interactionUrlResponseExample"]) ||
                    []
                  }
                />
              </div>
            </div>
            {/* Interaction/Query Configuration section end */}
          </div>
        </Spin>
        <Modal
          open={previewVisible}
          title={""}
          footer={null}
          onCancel={() => setPreviewVisible(false)}
        >
          <img
            alt="Icon Preview"
            style={{ width: "100%" }}
            src={previewImage}
          />
        </Modal>
      </div>
    </Modal>
  );
};

export default AddEditGenie;
