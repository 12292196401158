import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Table,
  Checkbox,
  Select,
  Col,
  Pagination,
  notification,
  Dropdown,
  Menu,
  Spin,
  InputNumber,
} from "antd";
import {
  SendOutlined,
  UserOutlined,
  UndoOutlined,
  CloseOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import CreateGroupDrawer from "./CreateGroupDrawer";
import {
  EditOutlined,
  ControlOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { axiosInstance } from "../DataLayer/axiosInstance";
import AddEditGenie from "./AddEditGenie";
import {
  GetAllGenie,
  ActivateOrDeactivateGenies,
  UpdateGenieOrder,
} from "../Services/GenieApiServices";
import MapGroupsToGenies from "./MapGroupsToGenies";

const GenieConfigurations = () => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState({ openDrawer: false, id: 0 });
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [isMGGOpen, setIsMGGOpen] = useState(false);

  const genieModal = {
    id: 0,
    name: "",
    description: "",
    image: "",
    tokenUrl: "",
    tokenUrlMethod: "",
    tokenUrlPayLoad: "",
    tokenUrlResponseMapping: "",
    tokenUrlResponseExample: "",
    interactionUrl: "",
    interactionUrlMethod: "",
    interactionUrlPayLoad: "",
    interactionUrlResponseMapping: "",
    interactionUrlResponseExample: "",
    interactionUrlUserNameKey: "",
    interactionUrlQueryKey: "",
    isActive: true,
    isGlobal: true,
    createdBy: 0,
    updatedBy: 0,
  };
  const [selectedData, setSelectedData] = useState(genieModal);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const { Search, TextArea } = Input;

  const genieColumns = [
    {
      title: "S.No",
      dataIndex: "index",
      render: (value, item, index) =>
        (pagination?.current - 1) * pagination?.pageSize + index + 1,
      width: 70,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => <span className="fs-10">{text}</span>,
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text, record) => <span className="fs-10">{text}</span>,
    },
    {
      title: "Icon",
      dataIndex: "image",
      key: "image",
      render: (icon) => (
        <img
          src={icon}
          alt="noImage"
          style={{ maxWidth: "70px", height: "45px" }}
        />
      ),
    },
    {
      title: "IsActive",
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive) => (isActive ? "true" : "false"),
    },
    {
      title: "IsGlobal",
      dataIndex: "isGlobal",
      key: "isGlobal",
      render: (isGlobal) => (isGlobal ? "true" : "false"),
    },
    {
      title: "OrderId",
      dataIndex: "isGlobal",
      key: "isGlobal",
      render: (isGlobal, record) => (
        <>
          {selectedRowKeys?.includes(record?.id) ? (
            <InputNumber
              size="small"
              min={1}
              max={100000}
              defaultValue={record?.orderId}
              onChange={(e) => {
                const updatedData = data.map((item) => {
                  if (item?.id == record?.id) {
                    item["orderId"] = e;
                  }
                  return item;
                });
                setData(updatedData);
              }}
            />
          ) : (
            <span>{record?.orderId}</span>
          )}
        </>
      ),
    },
    {
      title: "CreatedOn",
      dataIndex: "createdOn",
      render: (text, record) => (
        <span className="fs-10">
          {text ? new Date(text)?.toLocaleString() : text}
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <>
          <Button
            icon={<EditOutlined />}
            title="Edit"
            onClick={() => {
              setSelectedData(record);
              setIsOpen({ openDrawer: !isOpen?.openDrawer, id: record?.id });
            }}
          />
          &nbsp;
          <Button
            icon={<UsergroupAddOutlined />}
            title="Add to groups"
            onClick={() => {
              setSelectedData(record);
              setIsMGGOpen(true);
            }}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    renderData(true);
  }, []);

  const renderData = async (e) => {
    setLoading(true);
    try {
      const response = await GetAllGenie();
      if (e) {
        const groupResponse = await axiosInstance.get("/api/Group/GetGroup");
        if (groupResponse?.status === 200) {
          setGroupData(groupResponse?.data);
        } else {
          setGroupData([]);
        }
      }
      if (response && Array?.isArray(response)) {
        setFilteredData(response);
        setData(response);
      } else {
        setFilteredData([]);
        setData([]);
      }
      setLoading(false);
    } catch (error) {
      setFilteredData([]);
      setData([]);
      setLoading(false);
      if (e) {
        setGroupData([]);
      }
    }
  };

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      current: pagination.current,
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys, selectedRows) => {
      // console.log(selectedKeys, selectedRows);
      setSelectedRowKeys(selectedKeys);
    },
    // If you want to disable row selection based on condition
    // getCheckboxProps: (record) => ({
    //   disabled: record.status === 'inactive', // Disable checkbox if group is inactive
    // }),
  };

  const activateOrDeactivateRecords = async (status) => {
    try {
      setLoading(true);
      const data = {
        ids: selectedRowKeys,
        isActive: status,
      };
      const response = await ActivateOrDeactivateGenies(data);
      if (!response?.toLowerCase()?.includes("Something went wrong")) {
        setSelectedRowKeys([]);
      }
    } catch (error) {
      renderData();
    }
    renderData();
  };

  const updateOrder = async () => {
    try {
      setLoading(true);
      const dataToUpdateOrder = data
        ?.filter((f) => selectedRowKeys?.includes(f?.id))
        ?.map((e) => {
          return { genieId: e?.id, orderId: e?.orderId };
        });
      const response = await UpdateGenieOrder(dataToUpdateOrder);
      if (!response?.toLowerCase()?.includes("Something went wrong")) {
        setSelectedRowKeys([]);
      }
    } catch (error) {
      renderData();
    }
    renderData();
  };

  return (
    <div className="p-5">
      <Spin spinning={loading}>
        <div className="flex-btw-wrap align-i-c">
          <p>
            <b>Genie Configurations</b>
          </p>
          <Button
            type="primary"
            className="AddOrUpdateButton"
            size={"small"}
            style={{ width: "fit-content" }}
            onClick={() => {
              setIsOpen({ openDrawer: !isOpen?.openDrawer, id: 0 });
              setSelectedData(genieModal);
            }}
          >
            Create Genie
          </Button>
        </div>
        <div className="flex-btw-wrap">
          <div>
            <Search
              placeholder="Search by user..."
              // onChange={(e) => {
              //   setUserName(e?.target?.value || "");
              // }}
              style={{ width: "250px", height: "25px" }} // Adjust height if needed
              // value={userName}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <ReloadOutlined
              style={{ color: "blue", cursor: "pointer" }}
              title="refresh"
              onClick={() => {
                renderData();
              }}
            />
          </div>
          <div className="flex-nowrap">
            <Button
              ghost
              danger={!selectedRowKeys || selectedRowKeys?.length === 0}
              style={
                !selectedRowKeys || selectedRowKeys?.length === 0
                  ? {
                      width: "100%",
                      fontSize: "12px",
                      height: "25px",
                      padding: "0 10px",
                    }
                  : {
                      width: "100%",
                      fontSize: "12px",
                      height: "25px",
                      border: "1.5px solid green",
                      color: "green",
                      padding: "0 10px",
                    }
              }
              disabled={!selectedRowKeys || selectedRowKeys?.length === 0}
              onClick={() => updateOrder()}
            >
              Update Order
            </Button>
            <Button
              ghost
              danger={!selectedRowKeys || selectedRowKeys?.length === 0}
              style={
                !selectedRowKeys || selectedRowKeys?.length === 0
                  ? {
                      width: "100%",
                      fontSize: "12px",
                      height: "25px",
                      padding: "0 10px",
                    }
                  : {
                      width: "100%",
                      fontSize: "12px",
                      height: "25px",
                      border: "1.5px solid green",
                      color: "green",
                      padding: "0 10px",
                    }
              }
              disabled={!selectedRowKeys || selectedRowKeys?.length === 0}
              onClick={() => activateOrDeactivateRecords(true)}
            >
              Activate
            </Button>
            <Button
              ghost
              danger={!selectedRowKeys || selectedRowKeys?.length === 0}
              style={
                !selectedRowKeys || selectedRowKeys?.length === 0
                  ? {
                      width: "100%",
                      fontSize: "12px",
                      height: "25px",
                      padding: "0 10px",
                    }
                  : {
                      width: "100%",
                      fontSize: "12px",
                      height: "25px",
                      border: "1.5px solid green",
                      color: "green",
                      padding: "0 10px",
                    }
              }
              disabled={!selectedRowKeys || selectedRowKeys?.length === 0}
              onClick={() => activateOrDeactivateRecords(false)}
            >
              DeActivate
            </Button>
          </div>
        </div>
        <br />
        <div>
          <Table
            bordered
            rowSelection={rowSelection}
            size="small"
            dataSource={filteredData}
            columns={genieColumns}
            rowKey="id"
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "20", "50"],
              onChange: (page, pageSize) => {
                setPagination({ current: page, pageSize });
              },
              onShowSizeChange: (current, size) => {
                setPagination({ current: 1, pageSize: size }); // Reset to first page on page size change
              },
            }}
            onChange={handleTableChange} // Handle pagination changes
            scroll={{ y: 300 }}
          />
        </div>
        <div>
          <AddEditGenie
            key={isOpen?.openDrawer ? "opened" : "closed"}
            selectedData={selectedData}
            onSaveClick={(data) => {}}
            onCloseClick={(data) => {
              setIsOpen({ openDrawer: !isOpen?.openDrawer, id: 0 });
              setSelectedData(genieModal);
              if (data) {
                renderData();
              }
            }}
            isOpen={isOpen}
          />
          <MapGroupsToGenies
            isOpen={isMGGOpen}
            genieId={selectedData?.id}
            groups={groupData}
            onClose={() => {
              setIsMGGOpen(false);
            }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default GenieConfigurations;
