import React, { useEffect, useState, useRef } from "react";
import { Modal, Input, Button, Avatar } from "antd";
import {  SendOutlined,  UserOutlined,  UndoOutlined,  CloseOutlined} from "@ant-design/icons";
import {  AboutExdionChatBotResponse,  OtherExdionChatBotResponse,  dynamicTokenApi,  dynamicQueryApi} from "../Services/ChatBotApiServices";
import "../ChatBotStyles.css";
import { authContext } from "../CommonComponets/adalConfig";

export const DynamicChatBotComponent = (props) => {
  const chatContainerRef = useRef(null);
  const [userMessage, setUserMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([
    {
      id: 1,
      type: "bot",
      text: "Hi there...! How can I help you?",
      image_urls: [],
      status: "completed",
    },
  ]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState("");
  const [userData, setUserData] = useState(authContext.getCachedUser());

  useEffect(() => {
    return () => {
      setUserMessage("");
      setChatHistory([
        {
          id: 1,
          type: "bot",
          text: "Hi there...! How can I help you?",
          image_urls: [],
          status: "completed",
        },
      ]);
    };
  }, [props?.data?.isOpen]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [userMessage, chatHistory]);

  const handleImageClick = (url) => {
    setModalImageUrl(url);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setModalImageUrl("");
  };

  const handleSendMessage = () => {
    const message = userMessage.trim();
    if (message) {
      const data = chatHistory;
      const length = chatHistory?.length;
      data.push(
        {
          id: length + 1,
          type: "user",
          text: message,
          status: "completed",
        },
        {
          id: length + 2,
          type: "bot",
          text: "",
          status: "pending",
        }
      );
      setChatHistory(data);
      setUserMessage("");
      setTimeout(() => {
        getResponse(message, length + 2);
      }, 100);
    }
  };

  const getResponse = async (message, id) => {
    const selectedGenie = props?.data?.selectedGenie;
    let data = selectedGenie["interactionUrlPayLoad"];
    let tokenSet = {};
    try {
      data = JSON?.parse(data);
      if (selectedGenie["interactionUrlUserNameKey"]) {
        data[selectedGenie["interactionUrlUserNameKey"]] = userData?.userName;
      }
      if (selectedGenie["interactionUrlQueryKey"]) {
        data[selectedGenie["interactionUrlQueryKey"]] = message;
      }
      tokenSet = {
        url: selectedGenie["tokenUrl"],
        method: selectedGenie["tokenUrlMethod"],
        data: JSON?.parse(selectedGenie["tokenUrlPayLoad"]) || {},
        responseKey: selectedGenie["tokenUrlResponseMapping"],
      };
    } catch (error) {
      data = {
        session_id: userData?.userName || "Sam",
        category: "investors",
        query: message,
      };
    }
    let response = "";
    const queryResponse = await dynamicQueryApi(
      selectedGenie["interactionUrl"],
      selectedGenie["interactionUrlMethod"],
      data,
      tokenSet
    );
    if (typeof queryResponse === "string") {
      response = queryResponse;
    } else {
      response = queryResponse[selectedGenie["interactionUrlResponseMapping"]];
    }
    setChatHistory((prevChatHistory) =>
      prevChatHistory.map((chat) =>
        chat.id === id ? { ...chat, text: response, status: "completed", image_urls: (queryResponse["image_urls"] || []) } : chat
      )
    );
  };

  const handleCancel = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleRefresh = () => {
    setTimeout(() => {
      setChatHistory([{ type: "bot", text: "Hi there...! How can I help you?" }]);
    }, 1000);
  };

  document.onkeyup = function (e) {
    if (chatHistory?.filter((f) => f?.status === "pending")?.length > 0) {
      return;
    }
    if (e.which === 13) {
      handleSendMessage();
    }
  };

  return (
    <div>
      <Modal
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span>
              {props?.data?.selectedGenie?.description ||
                `${props?.data?.botName} ChatBot`}
            </span>

            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Button
                type="text"
                icon={<UndoOutlined style={{ transform: "rotate(120deg)" }} />}
                onClick={handleRefresh}
                title="Refresh Chat"
              />
              <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={handleCancel}
                title="Close Chat"
              />
            </div>
          </div>
        }
        maskClosable={false}
        open={props?.data?.isOpen}
        onCancel={handleCancel}
        footer={null}
        centered
        width="90%"
        closable={false}
        style={{ height: "90vh" }}
      >
        <hr />
        <div
          ref={chatContainerRef}
          style={{
            maxHeight: "60vh",
            overflowY: "auto",
            minHeight: "60vh",
            width: "100%",
          }}
        >
          {chatHistory.map((chat, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: chat.type === "user" ? "flex-end" : "flex-start",
                marginBottom: "10px",
              }}
            >
              {chat.type === "bot" && (
                <>
                  {chat?.status === "pending" ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Avatar
                        src={props?.data?.selectedGenie?.image}
                        alt={`${props?.data?.botName} bot`}
                        style={{
                          marginRight: "10px",
                          border: "#rgba(210, 204, 204, 0.76)",
                          boxShadow:
                            "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        }}
                      />
                      <div className="chat-loader">
                        <div className="dot-typing"></div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Avatar
                          src={props?.data?.selectedGenie?.image}
                          alt={`${props?.data?.botName} bot`}
                          style={{
                            marginRight: "10px",
                            border: "#rgba(210, 204, 204, 0.76)",
                            boxShadow:
                              "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                          }}
                        />
                        <div
                          style={{
                            backgroundColor: "#e6f7ff",
                            padding: "10px",
                            borderRadius: "10px",
                            maxWidth: "75%",
                            fontSize: "11px",
                            wordWrap: "break-word",
                          }}
                        >
                          {chat.text}
                        </div>
                      </div>
                      <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                        {chat?.image_urls?.map((url, imgIndex) => (
                          <img
                            key={imgIndex}
                            src={url}
                            alt={`Chat Image ${imgIndex}`}
                            style={{
                              width: "80px",
                              height: "80px",
                              cursor: "pointer",
                              objectFit: "cover",
                              border: "1px solid rgba(0,0,0,0.2)",
                            }}
                            onClick={() => handleImageClick(url)}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </>
              )}
              {chat.type === "user" && (
                <div
                  style={{
                    backgroundColor: "#f7f7f7",
                    padding: "10px",
                    borderRadius: "10px",
                    maxWidth: "75%",
                    wordWrap: "break-word",
                    fontSize: "11px",
                  }}
                >
                  {chat.text}
                </div>
              )}
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            marginTop: "5px",
            flexDirection: "row",
            gap: "5px",
          }}
        >
          <Input
            value={userMessage}
            onChange={(e) => setUserMessage(e.target.value)}
            placeholder="Type your message..."
            style={{ flex: 1 }}
          />
          <Button
            type="primary"
            icon={<SendOutlined />}
            onClick={handleSendMessage}
          />
        </div>
      </Modal>

      <Modal
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        centered
      >
        <img
          src={modalImageUrl}
          alt="Full Size"
          style={{ width: "100%", height: "100%" }}
        />
      </Modal>
    </div>
  );
};
