import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { baseUrl } from "../DataLayer/Constants";

const isTokenExpired = (token) => {
  if (!token) return true; // If no token is present, consider it expired
  try {
    const { exp } = jwtDecode(token);
    return exp * 1000 < Date.now();
  } catch (error) {
    console.error("Invalid token", error);
    return true;
  }
};

// sample payload
// {
//     "session_id": "",
//     "category": "it",
//     "query": "i am unable to send emails"
// }

export const AboutExdionChatBotResponse = async (data) => {
  try {
    let token = sessionStorage.getItem("AEToken");
    if (!token || isTokenExpired(token)) {
      await AETokenUpdate();
      token = sessionStorage.getItem("AEToken");
    }
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    data["category"] = "investors";
    const response = await axios.post(
      "https://apiexplore.exdionchatbot.com/query",
      data,
      {
        headers,
      }
    );
    if (response?.status === 200) {
      return response?.data?.response;
    } else {
      return "Something went wrong. Please try again...!";
    }
  } catch (error) {
    return "Something went wrong. Please try again...!";
  }
};

export const AETokenUpdate = async () => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const data = {
      username: "ExdionUser",
      password: "R5KmXAsu6VGjb7wph3J2d4tnFYaS8TvZ",
    };
    const response = await axios.post(
      "https://apiexplore.exdionchatbot.com/login",
      data,
      {
        headers,
      }
    );
    if (response?.status === 200) {
      sessionStorage.setItem("AEToken", response?.data?.token);
    }
  } catch (error) {}
};

export const OtherExdionChatBotResponse = async (data) => {
  try {
    let token = sessionStorage.getItem("OEToken");
    if (!token || isTokenExpired(token)) {
      await OETokenUpdate();
      token = sessionStorage.getItem("OEToken");
    }
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const response = await axios.post(
      "https://api-hr.exdionchatbot.com/query",
      data,
      {
        headers,
      }
    );
    if (response?.status === 200) {
      return response?.data?.response;
    } else {
      return "Something went wrong. Please try again...!";
    }
  } catch (error) {
    return "Something went wrong. Please try again...!";
  }
};

export const OETokenUpdate = async () => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const data = {
      username: "ExdionUser",
      password: "EVTZdyK8rq3vkRA5zBsNGMcfYP6WHwFJ",
    };
    const response = await axios.post(
      "https://api-hr.exdionchatbot.com/login",
      data,
      {
        headers,
      }
    );
    if (response?.status === 200) {
      sessionStorage.setItem("OEToken", response?.data?.token);
    }
  } catch (error) {}
};

export const dynamicTokenApi = async (url, method, data, responseKey) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    if (method?.toLowerCase() === "post") {
      const response = await axios.post(url, data, {
        headers,
      });
      if (response?.status === 200) {
        sessionStorage.setItem("chatbotToken", response?.data[responseKey]);
      }
    } else if (method?.toLowerCase() === "get") {
    }
  } catch (error) {
    console.log(error);
  }
};

export const dynamicQueryApi = async (url, method, data, tokenSet) => {
  try {
    let token = sessionStorage.getItem("chatbotToken");
    if (!token || isTokenExpired(token)) {
      await dynamicTokenApi(tokenSet?.url, tokenSet?.method , tokenSet?.data , tokenSet?.responseKey);
      token = sessionStorage.getItem("chatbotToken");
    }
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    if (method?.toLowerCase() === "post") {
      const response = await axios.post(url, data, {
        headers,
      });
      if (response?.status === 200) {
        return response?.data;
      } else {
        return "Something went wrong. Please try again...!";
      }
    } else if (method?.toLowerCase() === "get") {
    }
  } catch (error) {
    console.log(error);
    return "Something went wrong. Please try again...!";
  }
};
